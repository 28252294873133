.SetupRoot {
  padding: 60px;
  background: #F7F7F9;
}
.SetupRootSmall {
  padding: 30px;
}
.SetupContainer {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.SetupSmallContainer {
  @extend .SetupContainer;
  padding: 0 10px;
  & .StepContent {
    font-size: 14px;
  }
  & .SetupSlackButton {
    margin-top: 20px;
  }
}
.SetupSubtitle {
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
}
.SetupSlackButton {
  max-width: 150px;
  &:hover {
    background: transparent;
  }
}
.SetupSlackButtonSmall {
  margin-top: 0px;
}
.StepContent {
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 24px;
  padding: 16px 0;
}
.FirstStep {
  max-width: 285px
}
.SecondStep {
  max-width: 288px
}
.ThirdStep {
  max-width: 332px
}
.SetupLogoImg {
  margin: 10px 0;
}
