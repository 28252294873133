
body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Content {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #66657E;
  line-height: 24px;
}
.ContentSmall {
  font-size: 16px;
  font-weight: 400;
  color: #66657E;
}
.SecondaryContentSmall {
  font-size: 14px;
}
.Title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #242347;
}
.SecondaryTitle {
  margin-bottom: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #242347;
  line-height: 32px;
}
.SecondaryTitleSmall {
  margin-bottom: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #242347;
  line-height: 1.5;
}
.TitleSmall {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #242347;
}
.Image{
  max-width: 100%;
}

