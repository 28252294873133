@import "../../index.scss";
body {
  background-color: #FFFFFF;
}
.SuccessInstallLogoImg {
  margin-bottom: 55px;
}
.SuccessInstallTitleContainer {
  padding: 235px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & .Content {
    max-width: 515px;
  }
}