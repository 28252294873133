.FooterSection {
  margin-top: 30px;
}
.FooterContainer {
  padding: 16px 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.ContentSmall {
    line-height:  24px;
  }
}
.FooterSupportSection {
  padding: 0!important;
  background: #000000;
}
.FooterContent {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}
.FooterContentSmall {
  @extend .FooterContent;
  padding: 0 20px;
  font-size: 16px;
}
.FooterPrivacyPolicy {
  padding: 0!important;
  justify-content: center;

  & .FooterContainer {
    padding: 21px;
  }
  a {
    color: #242347;
    &:hover, :visited {
      color: #242347
    }
  }
}
