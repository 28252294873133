@import "../../index.scss";
.FeaturesRoot {
  p {
    font-family: 'IBM Plex Sans', sans-serif;
  }
}
.FeaturesContainer {
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FeaturesContainerSmall {
  padding: 0;
  text-align: center;
  & img {
    width: 100%!important;
  }
}
.FeaturesXSmallContainer {
  text-align: center;
  padding-left: 20px!important;
  padding-right: 20px!important;
  font-size: 14px;
}
.FeaturesXSmallGrid {
  flex-direction: column-reverse;
}
.FeaturesTitleContainer {
  padding: 110px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & .Content {
    max-width: 570px;
  }
}
.FeaturesSubTitleSmall {
  padding:0 40px;
  text-align: center;
}
.FeaturesTitleSmallContainer {
  @extend .FeaturesTitleContainer;
  padding: 40px;
}
.FeaturesTitleXSmallContainer {
  @extend .FeaturesTitleContainer;
  padding: 40px 20px;
}
.FeaturesContent {
  @extend .Content;
  max-width: 570px;
}
.ContentLeft {
  padding-left: 122px;
  align-items: flex-start;
  & .Content {
    max-width: 570px;
  }
  & .SecondaryTitle {
    max-width: 544px;
  }
}
.ContentRight {
  padding-right: 127px;
  align-items: flex-end;
  text-align: right;
  & .Content {
    max-width: 570px;
  }
}
.ContentSmallLeft {
  padding-left: 40px;
}