@font-face {
    font-display: swap;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src:
      local('IBM Plex Sans Bold'),
      local('IBMPlexSans-Bold'),
      url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
      url('../fonts/IBMPlexSans-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src:
      local('IBM Plex Sans Regular'),
      local('IBMPlexSans-Regular'),
      url('../fonts/IBMPlexSans-Regular.woff2') format('woff2'),
      url('../fonts/IBMPlexSans-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src:
      local('IBM Plex Sans Semi Bold'),
      local('IBMPlexSans-SemiBold'),
      url('../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
      url('../fonts/IBMPlexSans-SemiBold.woff') format('woff');
}
